<template lang="pug">
page-structure-element(
  :element="element"
  :defaultImage="getBackground(element)"
  :interactions="interactions"
  @clicked="navigateToPageOrTeaser(element)"
  @mouseenter="enterElement(element)"
  @mouseleave="leaveElement(element)"
) {{ selectedPage && selectedPage.isTeaser ? $t('tab') : pageTitle }}
</template>

<script>
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import PageStructureElement from '../PageStructureElement.vue';

  export default {
    components: { PageStructureElement },
    mixins: [pageStructureMixin],
    props: {
      element: {
        type: Object,
        required: true,
      },
      interactions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        defaultImage: {
          background: 'transparent',
        },
      };
    },
  };
</script>
